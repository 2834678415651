import React from 'react';
import styled from 'styled-components';
import Button from 'components/shared/Button';
import LazyImage from 'components/shared/LazyImage';
import Icon from 'components/shared/Icon';
import Content from 'components/shared/Content';
import heroText from 'assets/images/tekst-na-hero.svg';
import useMedia from 'hooks/useMedia';
import scrollToSection from 'utils/scrollToSection';
import emitMatomoEvent, { eventsNames } from 'utils/emitMatomoEvent';

const Wrapper = styled.header`
  ${Content} {
    position: relative;
    z-index: 2;
  }
  padding-bottom: 35px;
`;

const ImageWrapper = styled.div`
  width: 100%;
  max-height: 700px;
  overflow: hidden;
  ${({ theme }) => theme.mq.md} {
    width: calc(100% + 350px);
    margin-top: -130px;
    max-height: initial;
  }
`;

const IconWrapper = styled.div`
  width: 90%;
  margin-left: auto;
  ${({ theme }) => theme.mq.s} {
    width: 45%;
  }
`;

const ContentWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 50px;
  bottom: 0;
  z-index: 1;
  ${({ theme }) => theme.mq.s} {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const ButtonWrapper = styled.div`
  position: relative;
  width: 250px;
  margin-left: auto;
  margin-top: 30px;
  &:before {
    content: '';
    position: absolute;
    left: -6px;
    bottom: -5px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
  }
  &:hover ${Button} {
    transform: translate(-6px, 5px);
  }
  ${Button} {
    color: #fff;
  }
  ${({ theme }) => theme.mq.md} {
    min-width: 300px;
    margin-right: 5%;
    width: 35%;
    ${Button} {
      padding-top: 14px;
      padding-bottom: 14px;
      font-size: 2.2rem;
    }
  }
`;

const Header = ({ image1, imageMobile }) => {
  const matchesMD = useMedia('(min-width: 768px)');
  return (
    <Wrapper id="header">
      <Content>
        <ImageWrapper>
          <LazyImage src={matchesMD ? image1 : imageMobile} alt="" />
        </ImageWrapper>
        <ContentWrapper>
          <IconWrapper>
            <Icon src={heroText} full />
          </IconWrapper>
          <ButtonWrapper>
            <Button
              onClick={() => {
                emitMatomoEvent({
                  event: eventsNames.click_wybierz_mieszkanie,
                });
                scrollToSection('#mieszkania');
              }}
            >
              Wybierz mieszkanie
            </Button>
          </ButtonWrapper>
        </ContentWrapper>
      </Content>
    </Wrapper>
  );
};

export default Header;
