import React, { useContext } from 'react';
import styled from 'styled-components';
import { NavigationContext } from 'contexts/NavigationContext';
import Content from 'components/shared/Content';
import LazyImage from 'components/shared/LazyImage';
import Button from 'components/shared/Button';
import ScrollableSection from 'react-update-url-on-scroll';
import emitMatomoEvent, { eventsNames } from 'utils/emitMatomoEvent';

const Section = styled.section`
  position: relative;
  z-index: 3;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.mq.lg} {
    flex-direction: row;
  }
`;

const Heading = styled.h1`
  font-size: 2rem;
  font-weight: 400;
  color: #082737;
  ${({ theme }) => theme.mq.md} {
    font-size: 2.6rem;
  }
`;

const Description = styled.div`
  font-weight: ${({ theme }) => theme.light};
  padding: 20px 0 0;
  text-align: justify;
  p + p {
    margin-top: 20px;
  }
  ${({ theme }) => theme.mq.s} {
    text-align: left;
    padding: 30px 0;
  }
`;

const ProspectWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 30px 0 20px;

  ${Button} {
    max-width: unset;
    width: fit-content;
  }

  ${({ theme }) => theme.mq.s} {
    margin: 10px 0 20px;
  }

  ${({ theme }) => theme.mq.lg} {
    margin: 10px 0 50px;
  }
`;

const ProspectHeading = styled.h4`
  margin-bottom: 20px;
  color: #000000;
  font-size: ${({ theme }) => theme.fontSize.md};
  font-weight: ${({ theme }) => theme.semiBold};

  ${({ theme }) => theme.mq.lg} {
    margin-bottom: 25px;
  }
`;

const InnerWrapper = styled.div`
  width: 100%;
`;

const ImageWrapper = styled.div`
  width: 100%;
  padding: 20px 0 0 0;
  ${({ theme }) => theme.mq.lg} {
    padding: 0 0 0 20px;
  }
`;

const About = ({ heading, description, prospect, image }) => {
  const { initialSearchParam } = useContext(NavigationContext);

  return (
    <ScrollableSection name={`${initialSearchParam}#o-inwestycji`}>
      <Section id="o-inwestycji">
        <Content>
          <Wrapper>
            <InnerWrapper>
              <Heading dangerouslySetInnerHTML={{ __html: heading }} />
              <Description dangerouslySetInnerHTML={{ __html: description }} />
              {prospect?.aboutProspectAttachment &&
                prospect?.aboutProspectAttachment.link && (
                  <ProspectWrapper>
                    <ProspectHeading>
                      {prospect?.aboutProspectTitle}
                    </ProspectHeading>
                    <Button
                      as="a"
                      href={prospect?.aboutProspectAttachment.link}
                      target="_blank"
                      rel="noreferrer"
                      onClick={() => {
                        emitMatomoEvent({
                          event: eventsNames.click_prospekt_informacyjny,
                        });
                      }}
                    >
                      {prospect?.aboutProspectText}
                    </Button>
                  </ProspectWrapper>
                )}
            </InnerWrapper>
            <ImageWrapper>
              <LazyImage src={image} alt="background" />
            </ImageWrapper>
          </Wrapper>
        </Content>
      </Section>
    </ScrollableSection>
  );
};

export default About;
