import { useState, useEffect, useContext } from 'react';
import sortBy from 'browser/utils/sortBy';
import useMedia from 'browser/hooks/useMedia';
import useFilters from 'browser/hooks/useFilters';
import getUniqueFlatLayouts from 'browser/utils/getUniqueFlatLayouts';
import { store } from 'browser/store';

const toggleBodyOverflow = modalVisible => {
  if (typeof window !== `undefined`) {
    if (modalVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }
};

const useFlats = () => {
  const [formState, setFormState] = useState({
    visible: false,
    name: null,
    flatID: null,
    investment: null,
    bookFlat: false,
    businessUnit: false,
  });
  const [selectedFloorsText, setSelectedFloorsText] = useState('');
  const [saleOnly, setSaleOnly] = useState(false);
  const [activePage, setActivePage] = useState(0);
  const [viewType, setViewType] = useState('cards');
  const [sortProperty, setSortProperty] = useState({
    property: 'name',
    reverse: false,
  });
  const [flatsPerView, setFlatsPerView] = useState(4);
  const [pages, setPages] = useState([]);
  const [tour3DState, setTour3DState] = useState({
    src: null,
    flat: null,
  });

  const {
    state: { flats, allFlats, filters, favourites },
    dispatch,
  } = useContext(store);
  const filteredFlats = flats.filter(
    ({ status, isPromotion }) => status !== 3 && (saleOnly ? isPromotion : true)
  );

  const updateFlats = newFlats => {
    dispatch({
      type: 'UPDATE_FLATS',
      payload: newFlats,
    });
  };

  const updateFilters = (property, value) => {
    if (typeof property === 'object') {
      dispatch({
        type: 'UPDATE_FILTERS',
        payload: property,
      });
    } else
      dispatch({
        type: 'UPDATE_FILTERS',
        payload: { ...filters, [property]: value },
      });
  };

  const {
    handleAreaMin,
    handleAreaMax,
    handlePriceMin,
    handlePriceMax,
    handleRoomsMultiSelect,
    handleFloorMultiSelect,
    handleAreaRange,
    handlePriceRange,
  } = useFilters({
    allFlats,
    filters,
    updateFlats,
    updateFilters,
  });

  const matchesLG = useMedia('lg');

  const showTour3D = (tourSrc, flatName) => {
    setTour3DState({
      src: tourSrc,
      flat: flatName,
    });
  };

  const handleSetViewType = type => {
    setViewType(type);
    setActivePage(0);
  };

  const handleSetSortProperty = property => {
    setSortProperty(property);
    setActivePage(0);
  };

  const onButtonClick = name => {
    if (favourites.includes(name)) {
      dispatch({ type: 'REMOVE_FROM_FAVOURITES', payload: name });
    } else {
      dispatch({ type: 'ADD_TO_FAVOURITES', payload: name });
    }
  };

  const updatePages = (passedFlats = filteredFlats) => {
    const flatsToView =
      viewType === 'cards' ? getUniqueFlatLayouts(passedFlats) : passedFlats;

    const newPages = flatsToView
      .map((_, i, all) => all.slice(i * flatsPerView, (i + 1) * flatsPerView))
      .filter(x => x.length);

    setPages(newPages);
  };

  const toggleForm = (
    { flatID, name, investment },
    bookFlat = false,
    businessUnit = false
  ) => {
    toggleBodyOverflow(!formState.visible);
    setFormState({
      visible: !formState.visible,
      flatID,
      name,
      investment,
      bookFlat,
      businessUnit,
    });
  };

  const closeForm = () => {
    setFormState({
      ...formState,
      visible: false,
    });
    toggleBodyOverflow(false);
  };

  useEffect(() => {
    if (sortProperty) {
      const { property, reverse } = sortProperty;
      let newSortedFlats = filteredFlats.sort(sortBy(property, reverse));

      // sort - price on sale first when filtering by name
      if (property === 'name') {
        newSortedFlats = newSortedFlats.sort((a, b) => {
          if (!a.isPromotion && b.isPromotion) return 1;
          if (a.isPromotion && !b.isPromotion) return -1;
          return 0;
        });
      }

      updateFlats(newSortedFlats);
      updatePages(newSortedFlats);
    }
    if (pages.length && pages.length < activePage) setActivePage(0);
  }, [flatsPerView, sortProperty, allFlats.length]);

  useEffect(() => {
    if (viewType === 'cards') {
      setFlatsPerView(4);
      matchesLG && setFlatsPerView(6);
    } else if (viewType === 'list') setFlatsPerView(10);
  }, [matchesLG, viewType]);

  const firstFloor = filters.floors.all[0];
  const lastFloor = filters.floors.all[filters.floors.all.length - 1];
  const uniqueLayoutsNum = getUniqueFlatLayouts(filteredFlats).length;

  useEffect(() => {
    updatePages();
    if (firstFloor) {
      setSelectedFloorsText(
        filters.floors.selected.length === 0
          ? ` od ${firstFloor} do ${lastFloor}`
          : `: ${filters.floors.selected.sort((a, b) => a - b).join(', ')}`
      );
    }
  }, [filteredFlats.length, flatsPerView]);

  return {
    formState,
    tour3DState,
    filters,
    allFlats,
    saleOnly,
    filteredFlats,
    selectedFloorsText,
    firstFloor,
    lastFloor,
    viewType,
    pages,
    activePage,
    sortProperty,
    uniqueLayoutsNum,
    handleSetViewType,
    setSaleOnly,
    showTour3D,
    setTour3DState,
    handleSetSortProperty,
    updateFlats,
    onButtonClick,
    setActivePage,
    closeForm,
    toggleForm,
    handleRoomsMultiSelect,
    handleFloorMultiSelect,
    handleAreaMin,
    handleAreaMax,
    handlePriceMin,
    handlePriceMax,
    handleAreaRange,
    handlePriceRange,
  };
};

export default useFlats;
