import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Loader from 'browser/components/Loader';
import { LangContext } from 'browser/contexts/LangContext';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

const MainWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  margin-top: auto;
`;

const Main3D = styled.iframe`
  width: 100%;
  height: 100%;
`;

const LoaderWrapper = styled.div`
  opacity: 0;
  visibility: hidden;
  transition: 0.35s;
  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

const Topbar = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${({ theme }) => theme.sizes.navHeight};
  background: #fff;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.1);
  color: ${({ theme }) => theme.colors.text};
  font-size: 14px;
`;

const TopbarArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: calc(${({ theme }) => theme.sizes.navHeight} * 0.9);
  max-width: calc(${({ theme }) => theme.sizes.navHeight} * 0.9);
  height: 40px;
  width: 40px;
  border-radius: 8px;
  background: #e6e6e6;
  margin-right: 12px;
  transition: 0.3s;
  svg {
    width: 16px;
    height: 16px;
    display: block;
    transform: rotate(90deg);
    transition: 0.3s;
    margin: 0;
    fill: #111;
  }
`;

const TopbarBack = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: transparent;
  border: none;
  font-size: inherit;
  color: inherit;
  cursor: pointer;
  &:hover {
    & > div {
      background: ${({ theme }) => theme.colors.primary};
      svg {
        fill: #fff;
      }
    }
  }
`;

const TopbarText = styled.span`
  font-weight: 700;
  text-align: center;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.text};
  @media (max-width: 700px) {
    display: none;
  }
`;

const TourModal = ({ isVisible, close, src, flat }) => {
  const { translate } = useContext(LangContext);
  const [isLoading, setIsLoading] = useState(true);

  return (
    <Wrapper isVisible={isVisible}>
      <MainWrapper>
        <Topbar>
          <TopbarBack onClick={close}>
            <TopbarArrow>
              <svg
                fill="#ffffff"
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="5.896"
                viewBox="0 0 10 5.896"
              >
                <path
                  d="M.435,5.46,4.975,10l.92-.92L1.828,5,5.9.92,4.975,0,.435,4.54,0,5Z"
                  transform="translate(0 5.896) rotate(-90)"
                />
              </svg>
            </TopbarArrow>
            {translate('goBackToFlat')}
          </TopbarBack>

          <TopbarText>{`${translate('flat')} ${flat}`}</TopbarText>
        </Topbar>
        <LoaderWrapper visible={isVisible && isLoading}>
          <Loader
            visible={isVisible && isLoading}
            text={translate('loading_tour')}
            withBg
          />
        </LoaderWrapper>
        {(isVisible || !isLoading) && (
          <Main3D
            onLoad={() =>
              isLoading && setTimeout(() => setIsLoading(false), 350)
            }
            src={src}
          />
        )}
      </MainWrapper>
    </Wrapper>
  );
};

TourModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  src: PropTypes.string.isRequired,
  flat: PropTypes.string.isRequired,
};

export default TourModal;
