import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { LangContext } from 'browser/contexts/LangContext';
import { NavigationContext } from 'contexts/NavigationContext';
import { useHistory } from 'react-router-dom';
import { store } from 'browser/store';
import Icon from 'browser/components/Icon';
import useMedia from 'browser/hooks/useMedia';
import heartIcon from 'browser/assets/icons/heart.svg';
import { ReactComponent as FiltersSVG } from 'browser/assets/icons/filters.svg';

const Nav = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: ${({ theme }) => theme.sizes.navHeight};

  background: ${({ sidebarVisible }) =>
    sidebarVisible ? '#fff' : 'rgba(255, 255, 255, 0.57)'};
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.1);
  color: ${({ theme }) => theme.colors.text};
  font-size: 14px;
  @media (max-width: 991px) {
    background: #fff !important;
  }
`;

const NavArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  max-height: calc(${({ theme }) => theme.sizes.navHeight} * 0.9);
  max-width: calc(${({ theme }) => theme.sizes.navHeight} * 0.9);

  height: 40px;
  width: 40px;

  border-radius: 8px;
  background: #e6e6e6;
  margin-right: 12px;

  transition: 0.3s;
  svg {
    width: 16px;
    height: 16px;
    display: block;
    transform: rotate(90deg);
    transition: 0.3s;
    margin: 0;
    fill: #111;
  }
`;

const NavBack = styled.button`
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;

  background: transparent;
  border: none;

  font-size: inherit;
  color: inherit;

  cursor: pointer;
  &:hover {
    & > div {
      background: ${({ theme }) => theme.colors.primary};
      svg {
        fill: #fff;
      }
    }
  }
`;

const NavText = styled.span`
  font-weight: 700;
  text-align: center;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.text};
  @media (max-width: 576px) {
    display: none;
  }
`;

const Badge = styled.div`
  position: absolute;
  top: -4px;
  right: -2px;
  background: ${({ theme }) => theme.colors.primary};
  width: 18px;
  height: 18px;
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.1rem;
  font-weight: 700;
  border-radius: 50%;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OptionBtn = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  transition: 0.3s;
  span {
    position: absolute;
    right: calc(100% + 10px);
    top: 50%;
    transform: translate(20px, -50%);
    transition: 0.3s;
    opacity: 0;
    font-size: 13px;
    visibility: hidden;
    background: #fff;
    padding: 5px 10px;
    border-radius: 8px;
    white-space: nowrap;
    text-align: right;
    z-index: 2;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
    pointer-events: none;
    user-select: none;
  }
  @media (hover: hover) {
    &:hover {
      background: ${({ theme }) => theme.colors.primaryLight};
      border-color: ${({ theme }) => theme.colors.primaryLight};
      span {
        opacity: 1;
        visibility: visible;
        transform: translate(0, -50%);
      }
    }
  }
`;

const NavRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px 0 25px;
  height: 100%;
  background: #fff;
  @media (max-width: 576px) {
    background: transparent;
  }
`;

const FilterButton = styled(OptionBtn)`
  background: ${({ theme, active }) =>
    active ? theme.colors.primaryLight : '#fff'};
  margin-left: 10px;
  border: 2px solid transparent;
  svg {
    width: 100%;
    height: 100%;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.primaryLight};
  }
`;

const Navigation = () => {
  const matchesLG = useMedia('lg');
  const history = useHistory();
  const { translate } = useContext(LangContext);
  const isWishlist = history.location.pathname === '/favourites';

  const {
    state: {
      currentBuilding,
      currentFloor,
      currentCity,
      currentFlat,
      currentUrl,
      investment,
      isSidebarVisible,
      favourites,
    },
    dispatch,
  } = useContext(store);

  const isMultiBuilding = investment.multiBuilding && investment.buildingsMap;

  const goBack = () => {
    if (isSidebarVisible) {
      dispatch({ type: 'CHANGE_IS_SIDEBAR_VISIBLE', payload: false });
    } else if (currentBuilding && currentFloor && currentFlat) {
      if (currentFlat.isBusinessUnit) {
        history.push(`/building/${currentBuilding.url}`);
        return;
      }

      history.push(
        `/building/${currentBuilding.url}/floor/${currentFloor.level}`
      );
    } else if (currentBuilding && currentFloor) {
      history.push(`/building/${currentBuilding.url}`);
    } else if (currentBuilding) {
      history.push('/');
    } else {
      const prevURL = sessionStorage.getItem('prev-url') || '/';
      history.push(prevURL);
    }
  };

  let topbarText = '';
  if (isWishlist) {
    topbarText = translate('wishlist');
  } else if (currentBuilding && currentFloor && currentFlat) {
    topbarText = `${translate(
      currentFlat.isBusinessUnit ? 'apartment' : 'flat'
    )} ${currentFlat.name}`;
  } else if (currentBuilding && currentFloor) {
    topbarText = translate('choose_flat');
  } else if (currentBuilding) {
    topbarText = translate('choose_floor');
  } else if (currentCity) {
    topbarText = translate('choose_investment');
  } else if (investment.multiBuilding) {
    topbarText = translate('choose_building');
  } else {
    topbarText = translate('choose_city');
  }

  const goBackText = translate('back');

  const toggleSidebar = () => {
    dispatch({ type: 'CHANGE_IS_SIDEBAR_VISIBLE', payload: !isSidebarVisible });
  };

  const { setFavsLength } = useContext(NavigationContext);

  useEffect(() => {
    setFavsLength(favourites.length);
  }, [favourites.length]);

  return (
    <Nav
      sidebarVisible={isSidebarVisible}
      transparent={currentBuilding && !currentFloor}
    >
      {Boolean(
        (!isMultiBuilding && currentFloor) ||
          (isMultiBuilding && currentBuilding) ||
          isWishlist
      ) && (
        <NavBack onClick={goBack}>
          <NavArrow>
            <svg
              fill="#ffffff"
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="5.896"
              viewBox="0 0 10 5.896"
            >
              <path
                d="M.435,5.46,4.975,10l.92-.92L1.828,5,5.9.92,4.975,0,.435,4.54,0,5Z"
                transform="translate(0 5.896) rotate(-90)"
              />
            </svg>
          </NavArrow>
          {goBackText}
        </NavBack>
      )}

      <NavText>{topbarText}</NavText>
      <NavRight>
        <OptionBtn onClick={() => history.push(`/favourites`)}>
          <span>{translate('wishlist')}</span>
          <Icon src={heartIcon} alt="heart icon" auto />
          <Badge>{favourites.length}</Badge>
        </OptionBtn>
        {!(currentFlat || (currentUrl.includes('favourites') && matchesLG)) && (
          <FilterButton active={isSidebarVisible} onClick={toggleSidebar}>
            <span>{translate('filterFlats')}</span>
            <FiltersSVG />
          </FilterButton>
        )}
      </NavRight>
    </Nav>
  );
};

export default Navigation;
