import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { LangContext } from 'browser/contexts/LangContext';

const ImageWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: 550ms cubic-bezier(0.5, 0.32, 0.11, 1);
  opacity: 0;
  visibility: hidden;
  transform: scale(0.2);
  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    `}
`;

const Image = styled.img`
  display: block;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  object-fit: contain;
`;

const Label = styled.span`
  font-size: ${({ theme }) => theme.fontSize.md};
`;

const LoadedImage = ({ src, alt }) => {
  const { translate } = useContext(LangContext);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);

  return (
    <ImageWrapper visible={isLoaded || isError}>
      {isError ? (
        <Label>{translate('noPreview')}</Label>
      ) : (
        <Image
          onError={() => setIsError(true)}
          onLoad={() => setIsLoaded(true)}
          src={src}
          alt={alt}
        />
      )}
    </ImageWrapper>
  );
};

LoadedImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
};

LoadedImage.defaultProps = {
  src: '',
  alt: '',
};

export default LoadedImage;
