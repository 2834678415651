import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import scrollToSection from 'utils/scrollToSection';

const List = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  ${({ theme }) => theme.mq.lg} {
    flex-direction: row;
  }
`;

const LinkItem = styled.li`
  position: relative;
  color: #000;
  font-size: 1.8rem;
  margin: 12px 0;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s;
  ${({ theme }) => theme.mq.md} {
    font-size: ${({ theme }) => theme.fontSize.lg};
  }
  @media (hover: hover) {
    &:hover {
      &:after {
        transform: scaleX(1);
      }
    }
  }
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 50px;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.green};
    transition: 0.3s;
    transform: scaleX(0);
    transform-origin: 0 50%;
  }
  ${({ theme }) => theme.mq.lg} {
    font-size: 1.4rem;
    margin: 0 5px;
    white-space: nowrap;
  }
  ${({ theme }) => theme.mq.xl} {
    font-size: 1.5rem;
    margin: 0 10px;
  }
`;

const StyledLink = styled.a`
  color: inherit;
  text-decoration: none;
`;

const Links = ({ close }) => {
  return (
    <>
      <List>
        <LinkItem onClick={close}>
          <StyledLink onClick={() => scrollToSection('#o-inwestycji')}>
            O inwestycji
          </StyledLink>
        </LinkItem>
        <LinkItem onClick={close}>
          <StyledLink onClick={() => scrollToSection('#lokalizacja')}>
            Lokalizacja
          </StyledLink>
        </LinkItem>
        <LinkItem onClick={close}>
          <StyledLink onClick={() => scrollToSection('#mieszkania')}>
            Mieszkania
          </StyledLink>
        </LinkItem>
        <LinkItem onClick={close}>
          <StyledLink onClick={() => scrollToSection('#wykonczenia')}>
            Wykończenia
          </StyledLink>
        </LinkItem>
        <LinkItem onClick={close}>
          <StyledLink onClick={() => scrollToSection('#kredyt')}>
            Kredyt
          </StyledLink>
        </LinkItem>
        <LinkItem onClick={close}>
          <StyledLink onClick={() => scrollToSection('#galeria')}>
            Galeria
          </StyledLink>
        </LinkItem>
        <LinkItem onClick={close}>
          <StyledLink onClick={() => scrollToSection('#kontakt')}>
            Kontakt
          </StyledLink>
        </LinkItem>
      </List>
    </>
  );
};

Links.propTypes = {
  close: PropTypes.func,
};

Links.defaultProps = {
  close: () => null,
};

export default Links;
